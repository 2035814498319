import { useReducer } from "react";
import OfferService from "../services/OfferService";
import { useLoading } from "./useLoading";
import { useDispatch } from "react-redux";
import { addNotificationError } from "../actions/notificationAction";
import { useState } from "react";
import DocumentsService from "../services/DocumentsService";
import { setIsDownloadingCreditRequestDocument, setCreditRequestDocumentCode } from "../actions/offerAction";

let stateUseSearchOffers = {};

const reducer = (state, action) => {
    switch (action.type) {
        case "get":
            stateUseSearchOffers = { ...action.payload };
            break;
        default:
            stateUseSearchOffers = state;
            break;
    }

    return stateUseSearchOffers;
};

export const useSearchOffers = () => {
    const [{ Simulations: simulations, Offer: offer }, dispatchSearchOffers] = useReducer(reducer, {
        Simulations: null,
        Offer: null,
    });
    const [isSearched, setIsSearched] = useState(false);
    const [{ isLoading }, { start, stop }] = useLoading();
    const [{ isLoading: isLoadingOperation }, { start: startOperation, stop: stopOperation }] = useLoading();
    const dispatch = useDispatch();

    const getOffers = async (code) => {
        start();

        const offerService = new OfferService();
        const { data, status, errors } = await offerService.recoverOffers({ CodigoOferta: code });

        setIsSearched(true);

        if (status !== 200) {
            stop();
            dispatch(addNotificationError(errors));
            return;
        }

        dispatchSearchOffers({
            type: "get",
            payload: data,
        });

        stop();
    };

    const sendOfferToOperation = async (offer) => {
        startOperation();
        const offerService = new OfferService();
        const { status, errors } = await offerService.createOperation(offer);

        stopOperation();

        if (status !== 200) {
            stop();
            dispatch(addNotificationError(errors));

            return status;
        }

        await getOffers(offer.Code);

        return status;
    };

    const downloadCreditRequestDocument = (base64String) => {
        dispatch(setIsDownloadingCreditRequestDocument(true));
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = "solicitud_credito.pdf";
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        dispatch(setIsDownloadingCreditRequestDocument(false));
    };

    const generateCreditRequestDocument = async (proposalNumber) => {
        dispatch(setIsDownloadingCreditRequestDocument(true));

        const requestCreditRequest = {
            proposalNumber: proposalNumber ?? stateUseSearchOffers.Offer.CodigoOperacion,
        };
        const documentsService = new DocumentsService();
        const { data, status } = await documentsService.generateCreditRequestDocument(requestCreditRequest);

        if (status === 200) {
            dispatch(setCreditRequestDocumentCode(data.File));
            downloadCreditRequestDocument(data.File);
        } else {
            dispatch(setIsDownloadingCreditRequestDocument(false));
        }
    };

    return {
        simulations,
        offer,
        isLoading,
        isLoadingOperation,
        isSearched,
        getOffers,
        sendOfferToOperation,
        generateCreditRequestDocument,
        downloadCreditRequestDocument,
    };
};
