import {
    Main,
    ContentSection,
    Wrapper,
    Layout,
    Input,
    Spinner,
    Button,
    ErrorMessage,
} from "@vwfs-bronson/bronson-react";
import { useState } from "react";
import { useSearchOffers } from "../hooks/useSearchOffers";
import { SearchSimulations } from "../components/Search/SearchSimulations";
import { SearchOffers } from "../components/Search/SearchOffers";
import { SearchOperation } from "../components/Search/SearchOperation";
import { setCreditRequestDocumentCode } from "../actions/offerAction";
import { useDispatch } from "react-redux";

export const SearchPage = () => {
    const {
        isLoading,
        isSearched,
        simulations,
        offer,
        isLoadingOperation,
        sendOfferToOperation,
        getOffers,
        generateCreditRequestDocument,
        downloadCreditRequestDocument,
    } = useSearchOffers();
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const OFFER_CODE_MAX_LENGTH = 14;

    const handleOnClick = () => {
        dispatch(setCreditRequestDocumentCode(null));
        getOffers(search);
    };

    return (
        <Main>
            <ContentSection pageWrapSize="medium">
                <Wrapper>
                    <Layout>
                        <Layout.Item>
                            <h2 className="u-text-center">Buscador de ofertas</h2>
                        </Layout.Item>

                        <div className="u-mb">
                            <Layout center>
                                <Layout.Item default="1/3"></Layout.Item>
                                <Layout.Item default="1/3">
                                    <Input
                                        onChange={({ target }) => {
                                            setSearch(target.value.replace(/\s+/g, ""));
                                        }}
                                        placeholder="Código oferta"
                                        value={search}
                                        error={search.length > OFFER_CODE_MAX_LENGTH}
                                    ></Input>
                                    {search.length > OFFER_CODE_MAX_LENGTH && (
                                        <ErrorMessage>
                                            El código de oferta no puede tener más de {OFFER_CODE_MAX_LENGTH}{" "}
                                            caracteres.
                                        </ErrorMessage>
                                    )}
                                </Layout.Item>
                                <Layout.Item default="1/3">
                                    <Button
                                        onClick={handleOnClick}
                                        disabled={search.length === 0 || search.length > OFFER_CODE_MAX_LENGTH}
                                    >
                                        Buscar
                                    </Button>
                                </Layout.Item>
                                <Layout.Item default="1/1">
                                    <Spinner section small busy={isLoading}>
                                        {isSearched && (
                                            <>
                                                {!simulations && !offer ? (
                                                    <Layout.Item>
                                                        <h5 className="u-text-center u-mt-large">No hay datos</h5>
                                                    </Layout.Item>
                                                ) : (
                                                    <Layout.Item>
                                                        {simulations && (
                                                            <SearchSimulations
                                                                simulations={simulations}
                                                            ></SearchSimulations>
                                                        )}
                                                        {offer && (
                                                            <SearchOffers
                                                                isLoadingOperation={isLoadingOperation}
                                                                sendOfferToOperation={sendOfferToOperation}
                                                                offer={offer}
                                                                generateCreditRequestDocument={
                                                                    generateCreditRequestDocument
                                                                }
                                                            ></SearchOffers>
                                                        )}
                                                        {offer?.CodigoOperacion && (
                                                            <SearchOperation
                                                                operation={offer.CodigoOperacion}
                                                                generateCreditRequestDocument={
                                                                    generateCreditRequestDocument
                                                                }
                                                                downloadCreditRequestDocument={
                                                                    downloadCreditRequestDocument
                                                                }
                                                            ></SearchOperation>
                                                        )}
                                                    </Layout.Item>
                                                )}
                                            </>
                                        )}
                                    </Spinner>
                                </Layout.Item>
                            </Layout>
                        </div>
                    </Layout>
                </Wrapper>
            </ContentSection>
        </Main>
    );
};
