import React from "react";
import { useState } from "react";
import { Modal, Spinner, Button, Layout, PriceBox, Card } from "@vwfs-bronson/bronson-react";
import { financialFormat } from "../../helpers/financialFormat";
import UtilitiesService from "../../services/UtilitiesService";
import { PRODUCT_CODE } from "../../types/types";
import { useGlobals } from "../../hooks/useGlobals";

export const SearchOffers = ({ offer, sendOfferToOperation, isLoadingOperation, generateCreditRequestDocument }) => {
    const [operationCode, setOperationCode] = useState(null);
    const { isPricesWithTax, calculatorType } = useGlobals();

    const sendToOperation = async () => {
        const status = await sendOfferToOperation(offer);

        if (status === 200) {
            generateCreditRequestDocument();
        }
    };

    const handleOnClick = () => {
        if (UtilitiesService.isRVO()) window.open("./assets/pdfs/solicituddecredito_RE.pdf", "_blank");
        else window.open("./assets/pdfs/solicituddecredito_FI.pdf", "_blank");
    };

    return (
        <>
            <Layout center>
                <Layout.Item default="1/3" l="1/2" m="1/1">
                    <Card>
                        <Layout flush center>
                            <Layout.Item>
                                <h6 className="u-text-center">Código oferta</h6>
                                <h4 className="u-text-center">{offer.Code}</h4>
                            </Layout.Item>
                            <Layout.Item>
                                <div className="u-text-center">
                                    {offer?.ProdFam?.Key !== "FINA" && offer.Campain.Key + " - "}
                                    {offer.Campain.Value}
                                </div>
                                <PriceBox
                                    ariaLabelPrice="Price:"
                                    price={
                                        (isPricesWithTax && calculatorType === 1
                                            ? financialFormat(parseInt(offer.FeeAmount) + parseInt(offer.FeeTax))
                                            : financialFormat(parseInt(offer.FeeAmount))) + " €"
                                    }
                                    additionalInfo={
                                        calculatorType === 1 ? (isPricesWithTax ? "mes IVA incl." : "mes + IVA") : ""
                                    }
                                />
                                <div className="u-text-center">
                                    {offer.Months} meses{" "}
                                    {offer.Product.Key !== PRODUCT_CODE.LI && ` - ${financialFormat(offer.Km, 0)} km`}
                                </div>
                                <p className="u-text-center">
                                    {offer.InitAmount > 0
                                        ? `Entrada: ${financialFormat(offer.InitAmount)} €`
                                        : "Sin entrada"}
                                </p>
                                <p className="u-text-center">{offer.DesSituacion}</p>
                            </Layout.Item>
                            <Layout.Item className="u-text-center">
                                {offer.CodSituacion === "00" ? (
                                    !isLoadingOperation ? (
                                        <Button onClick={sendToOperation}>Iniciar operación</Button>
                                    ) : (
                                        <Spinner />
                                    )
                                ) : null}
                            </Layout.Item>
                        </Layout>
                    </Card>
                </Layout.Item>
            </Layout>
            <Modal
                show={!!operationCode}
                closeModal={() => setOperationCode(null)}
                title={"Operación generada con éxito"}
                id={"__operation_id_modal_" + offer.Code}
            >
                <div className="u-text-center">
                    <h5>Nº Operacion: {operationCode}</h5>
                    <div>
                        <center>
                            <Button
                                type={"link"}
                                text={"Descargar solicitud de crédito"}
                                icon={"[document-pdf]"}
                                iconPosition={"left"}
                                position="center"
                                onClick={handleOnClick}
                                className={"c-btn--secondary u-mt-small"}
                            />
                        </center>
                    </div>
                </div>
            </Modal>
        </>
    );
};
